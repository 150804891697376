import React from 'react';
import { LuMessagesSquare } from "react-icons/lu";
import { GiBrain } from "react-icons/gi";
import { MdOutlineReportProblem } from "react-icons/md";
import { FaBusinessTime } from "react-icons/fa6";


const Skillspanel = ({params}) => (
    <div className="card">
    <div className="card-header">
      <p className="ar">Soft Skills</p>
      <p className="en">مهارات الناعمة</p>

    </div>
    <div className="card-body">
    <hr></hr>

    <h2><LuMessagesSquare /></h2>
      <b>مهارات التواصل</b>
        <ul>
            <li>تكوين علاقات اجتماعية ناجحة</li>
        </ul>
        <hr></hr>
    <h2><FaBusinessTime /></h2>
    <b>مهارات التنظيم والتخطيط</b>
        <ul>
            <li>الالتزام بالمواعيد</li>
            <li>ترتيب الأولويات</li>
        </ul>
    <hr></hr>
    <h2><GiBrain /></h2>
      <b>مهارات التفكير الناقد</b>
        <ul>
            <li>القدرة علي توليد الأفكار</li>
        </ul>
        <hr></hr>
    <h2><MdOutlineReportProblem /></h2>
      <b>مهارات ادارة الازمات</b>
            <ul>
                <li>مهارة حل المشكلات</li>
            </ul>
        
    </div>
  </div>

);

export default Skillspanel;
