export const addBlog = (blog) => {
    return (dispatch, getState, {getFirebase} ) => {
        // const firebase = getFirebase();
        // const firestore = firebase.firestore();
        console.log("the action is ruuning")
        const firestore = getFirebase().firestore();

        const authorId = getState().firebase.auth.uid;

        firestore
        .collection("blogs")
        .add({
            ...blog,
            // authorId: authorId,
            creationDate: new Date()
        })
        .then(() => {
            console.log("added successfully")

            dispatch({
                type: "ADD_BLOG",
                blog
            });

        }).catch(err => {
            console.log("something went wrong", err)
            dispatch({
                type: "ADD_BLOG_ERR",
                err
            });

        });
    }
}