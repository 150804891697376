function calculateMonthsSince(startDate) {
    const now = new Date(); // Current date
    const start = new Date(startDate); // Start date

    // Calculate the difference in years and months
    let years = now.getFullYear() - start.getFullYear();
    let months = now.getMonth() - start.getMonth();

    // Adjust if the month difference is negative
    if (months < 0) {
        years--;
        months += 12; // Add 12 months to get the correct month difference
    }

    // Return the result as a string
    return years > 0 
        ? `${years} year${years > 1 ? 's' : ''} and ${months} month${months !== 1 ? 's' : ''}` 
        : `${months} month${months !== 1 ? 's' : ''}`;
}

export default calculateMonthsSince;
// Example usage
// const startDate = '2024-01-14';
// const monthsSince = calculateMonthsSince(startDate);
// console.log(`Months since ${startDate}: ${monthsSince}`);