import React from 'react';

// import { SiSaudia } from "react-icons/si";

import { MdWorkOutline } from "react-icons/md";
import MeImg from '../img/ksa.png'
import calculateMonthsSince from './CalcMonths'

const Experience = ({params}) => (

    <div className="card">
    <div className="card-header">
      
      <p className="ar">Experience <MdWorkOutline /> </p>
      <p className="en">  <MdWorkOutline / > الخبرة</p>

    </div>
    <div className="card-body">
    
    {/* <h2><MdWorkOutline /></h2> */}
    <img src={MeImg} style={{width: '30px', height: '60px'}}/>

    <h3>Confidential Government</h3>
    <h3>جهة حكومية سرية</h3>
    <small>2024 January ({calculateMonthsSince('2024-01-14')})</small>
    <p>Enablement Officer</p>
    <p>IT Project Manager</p>
    <p>Enterprise Architecture</p>
    </div>
  </div>

);

export default Experience;
