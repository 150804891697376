import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";

var DBConnect = {
    apiKey: "AIzaSyA98mIFShKVHSaEjkGFAUd_jZwWgwzaWs8",
    authDomain: "ibrahimweb-bece6.firebaseapp.com",
    projectId: "ibrahimweb-bece6",
    storageBucket: "ibrahimweb-bece6.firebasestorage.app",
    messagingSenderId: "1049190140821",
    appId: "1:1049190140821:web:851d65bbfaf1613c97c49c",
    measurementId: "G-XHTP0B3V2R"
};

// firebase.initializeApp(DBConnect)
// const db = firebase.firestore();

firebase.initializeApp(DBConnect);
firebase.firestore();


export default firebase; 