import React, { Component } from 'react';
import PostInput from '../../Shared/PostInput';
import {connect} from "react-redux"
import {addWorks} from '../../redux/action/worksAction'
// ES6
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// CommonJS

class PostWorks extends Component {

  state = {
    work: "",
  }

  handleValueText = (e) => {
    console.log("from here", e);
    this.state.work = e    
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    console.log(e)
    e.preventDefault();
    this.props.addWorks(this.state);
    console.log(this.state)
    console.log("Hi")
  }

  render() {
    
    return (
      <div className="content" id ="content">
            <div className="panel-center">

                {/* <div className="card" style={{height: '100%'}}>
                  <div className="card-header">
                    <p className="ar">[ IBRAHIM works post ]</p>
                  </div>

                  <div className="card-body" style={{textAlign: 'left'}}>
                  </div>

                </div> */}

              </div>


                <form 
                    id="addTaskForm"
                    className="container"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                >
                <div className="panel-center">
                    <h3>Post work</h3>

                    <p>ID: </p>
                        <input type="text" placeholder="ID" className="inputEnter" id="id" onChange={this.handleChange}/>
                    <p>title</p>
                        <input type="text" placeholder="title" className="inputEnter" id="title" onChange={this.handleChange}/>
                    <p>date</p>
                        <input type="date" placeholder="Date" className="inputEnter" id="date" onChange={this.handleChange}/>
                    <p>brief</p>
                        <textarea placeholder="Description" id="brief" onChange={this.handleChange}></textarea>


                    <select id="status" onChange={this.handleChange}>
                      <option value="public">Publish</option>
                      <option value="draft">Draft</option>
                    </select>
                    <p>content</p>
                    <PostInput handleValueText={this.handleValueText}/>
                  {/* <ReactQuill
                      onChange={this.handleValueText} 
                      // onChange={this.previewCode}
                      theme="snow"                      
                  /> */}

                </div>
                  <div className="panel-left">
                    <button type="submit">new</button>
                    <button>Delete the post</button>
                    <button>Update the post</button>

                  </div>
                </form>
               
             
              </div>

    )
  }

}


const mapDispatch = dispatch => {
  return {
    addWorks: work => dispatch(addWorks(work))
  }
}

export default connect(null, mapDispatch) (PostWorks);
