import React from 'react'
import '../App.css'
import firebase from 'firebase/app'
import 'firebase/database'
import config from './DBConnect'
import { remove } from 'firebase/database'


class Connector extends React.Component {
    constructor(props) {
        super(props); // Don't forget to call super
        this.state = {
            isConnected: false,
            database: null,
            myId: '',
            reciverId: '',
            message: [], // Initialize message as an array
            title: '',
            summary: '',
            content: '',
            publishDate: '',
            isPublish: '',
            category: '',
        };
    }

    // ...other methods unchanged...

    connect = async () => {
        try {
            const { database, myId } = this.state;

            // Correctly call remove() on the reference
            await database.ref('/notifs/' + myId).remove();

            await database.ref('/notifs/' + myId).on('value', snapshot => {
                if (snapshot.exists()) {
                    const notif = snapshot.val();

                    this.setState(prevState => ({
                        message: [...prevState.message, notif] // Use prevState to update message
                    }));
                }
            });

            this.setState({
                isConnected: true
            });
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const { title, summary, content, publishDate, isPublish, category, reciverId, message, myId } = this.state;

        return (
            <div>
                <div className="content" id="content">
                    <div className="panel-center">
                        {this.state.isConnected ? (
                            <div>
                                <input type="text" placeholder="Title here.." className="inputEnter" onChange={(e) => this.setState({ reciverId: e.target.value })} value={reciverId} />
                                <textarea placeholder="Please enter your text here" onChange={(e) => this.setState({ message: e.target.value })} value={message}></textarea>
                                <h1>Received messages: {message.map(this.renderMessages)}</h1>
                            </div>
                        ) : (
                            <div>
                                <h1>My ID</h1>
                                <input type="text" placeholder="MyId" className="inputEnter" onChange={(e) => this.setState({ myId: e.target.value })} value={myId} />
                                <button onClick={this.connect}>Connect</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Connector;