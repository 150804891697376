

const blogReducer = (state = {}, action) => {
    switch(action.type){
        case "ADD_BLOG":
            {
                console.log("added task")
                return state
            }
        case "ADD_BLOG_ERR":
            {
                console.log("an erro occured with  task")
                return state
            }

        default: return state
    }
};

export default blogReducer;