import React, { Component } from 'react';
import PostInput from '../../Shared/PostInput';
import {connect} from "react-redux"
import {addBlog} from '../../redux/action/blogAction'
// ES6
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// CommonJS

class PostWorks extends Component {

  state = {
    blog: "",
  }

  handleValueText = (e) => {
    console.log("from here", e);
    this.state.blog = e    
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    console.log(e)
    e.preventDefault();
    this.props.addBlog(this.state);
    console.log(this.state)
    console.log("Hi")
  }

  render() {
    
    return (
      <div className="content" id ="content">
            <div className="panel-center">

                {/* <div className="card" style={{height: '100%'}}>
                  <div className="card-header">
                    <p className="ar">[ IBRAHIM works post ]</p>
                  </div>

                  <div className="card-body" style={{textAlign: 'left'}}>
                  </div>

                </div> */}

              </div>


                <form 
                    id="addTaskForm"
                    className="container"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                >
                <div className="panel-center">

                    <input type="text" placeholder="Titile here.." className="inputEnter" id="title" onChange={this.handleChange}/>
                    <input type="number" placeholder="Category 1-6" className="inputEnter" id="category" onChange={this.handleChange}/>

                    <input type="date" placeholder="Date" className="inputEnter" id="date" onChange={this.handleChange}/>
                    <select id="status" onChange={this.handleChange}>
                      <option value="public">Publish</option>
                      <option value="draft">Draft</option>
                    </select>

                  <textarea placeholder="Description" id="description" onChange={this.handleChange}></textarea>
                  <PostInput handleValueText={this.handleValueText}/>
                  {/* <ReactQuill
                      onChange={this.handleValueText} 
                      // onChange={this.previewCode}
                      theme="snow"                      
                  /> */}

                </div>
                  <div className="panel-left">
                    <button type="submit">new</button>
                    <button>Delete the post</button>
                    <button>Update the post</button>

                  </div>
                </form>
               
             
              </div>

    )
  }

}


const mapDispatch = dispatch => {
  return {
    addBlog: blog => dispatch(addBlog(blog))
  }
}

export default connect(null, mapDispatch) (PostWorks);
