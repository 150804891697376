import React from 'react';
import SearchBar from '../Components/SearchBar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { GiRayGun } from 'react-icons/gi';



const blogs = [
  { 
    id: 1,
    title: 'Data structures', 
    brief: 'wadokwoakdowad',
    isSeries: false,
  },
  {
    id: 2,
    title: 'Baisc web', 
    brief: 'wadokwoakdowad',
    isSeries: false,
  },
  {
    id: 3,
    title: 'Google', 
    brief: 'wadokwoakdowad',
    isSeries: false,
  },
  {
    id: 4,
    title: 'Hi', 
    brief: 'wadokwoakdowad',
    isSeries: false,
  }
]


const Blogs = ({blogsM}) => {
  
  function showCategory(c) {
    switch(c){
      case 1:
        return "Programming 💻"
      case 2:
        return "Cybersecurity 🔒"
      case 3:
        return "Technology 🧑🏽‍💻"
      case 4:
        return "Project management 💼"
      case 5:
        return "Emotional Intelligence 🧠"
      case 6:
        return "other 😬"

    }
  }
  
  return (
      <div className="content" id ="content">

         <div className="panel-left">
              <div className="side-nav">
                <ul className="sub-side">
                    {/* <li>
                      <details>
                          <summary>Epcot Center</summary>
                          <ol>
                            <li>one</li>
                            <li>two
                              <ol>
                                <li>two.one</li>
                                <li>two.two</li>
                                <li>two.three</li>
                              </ol>
                            </li>
                            <li>three
                              <ol>
                                <li>three.one</li>
                                <li>three.two
                                  <ol>
                                    <li>three.two.one</li>
                                    <li>three.two.two</li>
                                  </ol>
                                </li>
                              </ol>
                            </li>
                            <li>four</li>
                          </ol>
                        </details>
                    </li> */}

                    
                    {/* <li>كيف تسوي بوت واتس اب </li>
                    <li>Hi</li>
                    <li>Hi</li> */}
                    <h3>What I talk about ?</h3>
                    <div style={{display: "block"}}>
                      <button>Programming 💻</button>
                      <button>Cybersecurity 🔒</button>
                      <button>Technology 🧑🏽‍💻</button>
                      <button>Project management 💼</button>
                      <button>Emotional Intelligence 🧠</button>
                      <button>other 😬</button>
                  </div>

                </ul>

                
                </div>
            </div>
            
            <div className="panel-center">
                <search>
                  <form>
                    <SearchBar placeholder="Search for a blog"/>
                  </form>
                </search>
                <Link to="/postblog"><button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i> Add a new blog+</button></Link>
                


                <div className="card">
                  <div className="card-header">
                    <p className="ar">[ IBRAHIM blogs ]</p>
                  </div>

                  <center>
                    {!blogsM && <div class="lds-ripple"><div></div><div></div></div>}
                  </center>  
                                  
                  <div className="card-body" style={{textAlign: 'left'}}>
                    
                    <main>
                      
                      {
                        blogsM && blogsM.map(m => 
                          <article key={m.id}>
                            <h2>{m.title} : <label style={{background: 'gray', padding: '5px', textAlign: 'center', fontSize: '12px', fontWeight: 'bold', borderRadius: '10px'}}>{ showCategory(parseInt(m.category))}</label></h2>
                            {/* <p style={{background: 'gray', padding: '5px', width: '10%', textAlign: 'center'}}>{m.status}</p>                             */}
                            <p>{m.description} 
                            <button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i>  View more details</button>
                            {/* <button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i>  Edit blog</button> */}
                            {/* <button style={{width: '50%'}}><i class="fa fa-eye" aria-hidden="true"></i>  Delete blog</button> */}
                            </p>
                          </article>   
                        )
                      }
                    </main>

                  </div>

                </div>

              </div>

            <div className="panel-right">
                      
            </div>
           
          </div>
  )
}

// export default Blogs;
const mapStateToProps = (state) => {
  console.log(state);
  const blogsM = state.firestore.ordered.blogs;
  return {
    blogsM: blogsM,
    uid: state.firebase.auth.uid
  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect((ownProps) => [
    {
      collection: "blogs",
      // where: ["authorId", "==", ownProps.uid],
      // orderBy: ["date", "desc"],
    },
  ])
)(Blogs);