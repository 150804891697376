

const workReducer = (state = {}, action) => {
    switch(action.type){
        case "ADD_WORK":
            {
                console.log("added work")
                return state
            }
        case "ADD_WORK_ERR":
            {
                console.log("an erro occured with work")
                return state
            }

        default: return state
    }
};

export default workReducer;