import React from 'react';
import '../css/main.css';
import SoicalPanel from '../Shared/SoicalPanel';
import Skillspanel from '../Shared/SkillsPanel';
import SoftSkillsPanel from '../Shared/SoftSkillsPanel';

import MeImg from '../img/MeIbrahim2.jpeg'
import MeImg2 from '../img/MeIbrahim.jpeg'

import CountUp from 'react-countup';
import Experience from '../Shared/Experience';

const Home = ({params}) => {
    return (
        <div className="container">
        {/* <?php include 'inc/page_settings/header.php'; ?>
        <!-- Navbar -->
        <?php include 'inc/page_settings/navbar.php';?>
        <!-- content --> */}
  
        <div className="content" id ="content">

          <div className="panel-center">
            <div style={{textAlign: 'center'}}>
              <div className="card-x">
                <b className="number"><CountUp delay={1} end={23800} duration={10}/>+</b>
                <br/>
                <i class="fa fa-eye" aria-hidden="true"></i> Vistis my website
              </div>
              <div className="card-x">
                <b className="number"><CountUp delay={1} end={15} duration={10}/>+</b>
                <br/>
                <i class="fa fa-wrench" aria-hidden="true"></i> Projects
              </div>
              <div className="card-x">
                <b className="number"><CountUp delay={1} end={3} duration={10}/>+</b>
                <br/>
                <i class="fa fa-rss-square" aria-hidden="true"></i> Blogs
              </div>
              <br/>
   
       
              <div className="card-x">
                <b className="number"><CountUp delay={1} end={3000} duration={10}/>+</b>
                <br/>
                <i class="fa fa-youtube-play" aria-hidden="true"></i> Views on youtube
              </div>
            </div>
            

              <div className="card">
                  <div className="card-body">
                    <img src={MeImg} style={{width: '159.14px', height: '200.9px'}}/>
                    <br/>
                    <img src={MeImg2} style={{width: '159.14px', height: '350.9px'}}/>

                    <h2>COMPUTER INFORMATION SYSTEMS</h2>
                    <h3>نظم معلومات حاسب</h3>
                    <br/>
                    <iframe src="https://www.youtube.com/embed/bbchBLgKgm4?si=iYI51deRRNRmoyXX" style={{width: '90%', height: '260px'}}></iframe>
                </div>
              </div>
  
  
              <div className="card">
                <div className="card-header">
                  <p>Me</p>
                </div>
                <div className="card-body">
                  <h2 className="title-subject">مقدمة عني</h2>
                  <p> 1  لدي شغف ببناء وتصميم الحلول المتطورة. سجل حافل في حل المشكلات المعقدة من خلال التفكير الابتكاري والاهتمام الدقيق بالتفاصيل. متعلم سريع مع قدرة قوية على التكيف ومواكبة مع التقنيات الجديدة.  </p>
                  <p>I am passion for building and designing cutting-edge solutions. Proven track record of solving complex problems through innovative thinking and meticulous attention to detail. Quick learner with a strong aptitude for adapting to new technologies and frameworks.</p>
                </div>
              </div>
              
            </div>
          <div className="panel-right">
            <SoftSkillsPanel />
            <Skillspanel />

          </div>
            <SoicalPanel />
        </div>
    </div>
    )
}

export default Home;
