import React from 'react';

import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');

import 'quill/dist/quill.snow.css'; // Add css for snow theme
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

export default ({handleValueText}) => {
  const counterRef = React.useRef();
  const { quill, quillRef, Quill } = useQuill({ modules: { counter: true } });

  if (Quill && !quill) {
    // For execute this line only once.
    Quill.register('modules/counter', function(quill, options) {
      quill.on('text-change', function() {
        const text = quill.getText();
        // There are a couple issues with counting words
        // this way but we'll fix these later
        counterRef.current.innerText = text.split(/\s+/).length;
      });

    });

  }


  React.useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        
        // console.log(quill.getText()); // Get text only
        // console.log(quill.getContents()); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill
        // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        handleValueText(quillRef.current.firstChild.innerHTML)

      });
    }
  }, [quill]);

  return (
    <div style={{ width: '100%', height: '500px'}}>
      <div ref={quillRef}/>
      <div ref={counterRef} />

    </div>
  );
};
